body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.CenteredSpin {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 99;
  width: 100%;
  background: white;
  box-shadow: 0 0 10px #0002;
}

.suggestion-item,
.suggestion-item--active {
  padding: 4px 8px;
}

.autocomplete-dropdown-container.hidden {
  display: none;
}

.autocomplete-dropdown-container-tip {
  text-align: right;
  font-size: 0.8em;
  color: #0008;
}

#root {
  height: 100%;
}

.driver-tile {
  padding: 9px !important;
}

.driver-tile:hover {
  background-color: #0001;
  cursor: pointer;
}
