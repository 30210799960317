.header {
  background-color: #1890ff;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  width: 100%
}

.header__statuses {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 10px;
  line-height: 1.3;
  height: auto;
}

.header__link {
  font-size: 13px;
  color: #ffffffcc;
  font-weight: 600;
  cursor: pointer;
}

.header__link:hover {
  color: #fff;
}

.header__link.active {
  color: #fff;
}

.header__link--hidden {
  display: none;
}

.header__link--expand {
  text-decoration: underline;
}

.header__link--show {
  display: inline;
}

.centered-spinner {
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-table table {
  table-layout: fixed !important;
}

.ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.driver-battery-level {
  border-radius: 5px;
  border: 1px solid;
  width: 35px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  position: relative;
  background: #fff;
}

.driver-battery-level::after {
  content: "";
  display: block;
  position: absolute;
  right: -3px;
  top: 6px;
  border-radius: 0 5px 5px 0;
  width: 3px;
  height: 6px;
  background: black;
}

.driver-battery-level--progress {
  top: 0;
  height: 100%;
  position: absolute;
  border-radius: 5px;
}

.driver-battery-level--text {
  z-index: 2;
  position: relative;
}

.tag-autocomplete-container {
  display: flex;
  align-items: center;

}

.tag-autocomplete-input {
  height: 22px;
  width: 250px !important;
  margin: 0 !important;
}

.ant-select-selection-placeholder {
  position: relative;
  top: -4px;
  font-size: 13px;
}

.tag-autocomplete-plus-button {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  padding: 0;
}

.ant-switch-checked {
  background-color: #001529;
}