.drawer {
  margin-top: 10px;
  padding: 8px;
}

.drawer__header {
  margin-top: 0;
  text-align: center;
}

.drawer__selection-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
}

.client-drawer {
  display: grid;
  grid-template-areas:
    "portrait fullname fullname fullname fullname phone phone phone phone"
    "location country country country country city city city city"
    ". district street street street street street street street";
  gap: 14px 8px;
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.client-drawer__icon--portrait {
  grid-area: portrait;
}

.client-drawer__icon--location {
  grid-area: location;
}

.client-drawer__icon--empty {
  grid-area: empty;
}

.client-drawer__field--fullname {
  grid-area: fullname;
}

.client-drawer__field--phone {
  grid-area: phone;
}

.client-drawer__field--country {
  grid-area: country;
}

.client-drawer__field--city {
  grid-area: city;
}

.client-drawer__field--district {
  grid-area: district;
}

.client-drawer__field--street {
  grid-area: street;
}

.order-drawer .ant-form-item {
  margin-bottom: 2px !important;
}

.order-drawer .ant-list-item {
  padding: 2px 0px !important;
}